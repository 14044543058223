<template>
  <i
    class="bi bi-list mr-1 text-white toggle-sidebar fs-5 cursor-pointer"
    @click="
      {
        issidebaropen = !this.issidebaropen;
      }
    "
  ></i>
  <div
    v-bind:class="
      issidebaropen == true
        ? 'main-sidebar  sidebar-fixed '
        : 'mini-sidebar sidebar-fixed'
    "
  >
    <div class="overflow-auto">
      <div
        v-bind:class="
          issidebaropen == true
            ? 'row content border sidebar-bg-color-set  h-100 sidebar-fixed sidebar-width '
            : 'row content border sidebar-bg-color-set  h-100 sidebar-fixed'
        "
      >
        <div class="col-lg-2 sidenav pl-0 d-flex flex-column">
          <div class="">
            <router-link to="/win" class="text-white" target="_blank">
              <p
                class="text-capitalize fs-2 fw-bold d-flex align-items-center ml-2 coustom-sidebar-width"
              >
                <i class="bi bi-controller mr-1 text-white"></i>

                <span v-bind:class="'sidebar-item'">Go To Game</span>
              </p>
            </router-link>
          </div>
          <router-link to="/admin/desktop" class="text-white">
            <div
              class="coustom-add-width-sidebar-menu"
              :class="{ 'active-menu': $route.path === '/admin/desktop' }"
            >
              <div>
                <p
                  class="text-capitalize fs-2 fw-bold d-flex align-items-center ml-2 coustom-sidebar-width"
                  :class="{ 'active-menu': $route.path === '/admin/desktop' }"
                >
                  <i class="bi bi-house-door-fill mr-1 text-white"></i>

                  <span v-bind:class="'sidebar-item'">Dashboard</span>
                </p>
              </div>
            </div>
          </router-link>

          <router-link to="/admin/setresultgame" class="text-white">
            <div
              class="coustom-add-width-sidebar-menu"
              :class="{ 'active-menu': $route.path === '/admin/setresultgame' }"
            >
              <div>
                <p
                  class="text-capitalize fs-2 fw-bold d-flex align-items-center ml-2 coustom-sidebar-width"
                  :class="{
                    'active-menu': $route.path === '/admin/setresultgame',
                  }"
                >
                  <i class="bi bi-joystick mr-1 text-white"></i>

                  <span v-bind:class="'sidebar-item'">Set Result Game</span>
                </p>
              </div>
            </div>
          </router-link>
          <router-link to="/admin/periodhistory" class="text-white">
            <div
              class="coustom-add-width-sidebar-menu"
              :class="{ 'active-menu': $route.path === '/admin/periodhistory' }"
            >
              <div>
                <p
                  class="text-capitalize fs-2 fw-bold d-flex align-items-center ml-2 coustom-sidebar-width"
                  :class="{
                    'active-menu': $route.path === '/admin/periodhistory',
                  }"
                >
                  <!-- <i class="bi bi-clock-history mr-1 text-white"></i> -->
                  <i class="bi bi-hourglass mr-1 text-white"></i>
                  <span v-bind:class="'sidebar-item'">Period History</span>
                </p>
              </div>
            </div>
          </router-link>
          <router-link to="/admin/alluser" class="text-white">
            <div
              class="coustom-add-width-sidebar-menu"
              :class="{ 'active-menu': $route.path === '/admin/alluser' }"
            >
              <div>
                <p
                  class="text-capitalize fs-2 fw-bold d-flex align-items-center ml-2 coustom-sidebar-width"
                  :class="{
                    'active-menu': $route.path === '/admin/alluser',
                  }"
                >
                  <i class="bi bi-people-fill mr-1 text-white"></i>

                  <span v-bind:class="'sidebar-item'">All Users</span>
                </p>
              </div>
            </div>
          </router-link>
          <router-link to="/admin/userwallethistory" class="text-white">
            <div
              class="coustom-add-width-sidebar-menu"
              :class="{
                'active-menu': $route.path === '/admin/userwallethistory',
              }"
            >
              <div>
                <p
                  class="text-capitalize fs-2 fw-bold d-flex align-items-center ml-2 coustom-sidebar-width"
                  :class="{
                    'active-menu': $route.path === '/admin/userwallethistory',
                  }"
                >
                  <i class="bi bi-wallet2 mr-1 text-white"></i>

                  <span v-bind:class="'sidebar-item'">User Wallet History</span>
                </p>
              </div>
            </div>
          </router-link>
          <!-- Recharge Hestory Drowp Down -->
          <div
            class="coustom-add-width-sidebar-menu"
            @pointerdown="isAccountCollapseActive == true"
            @pointerup="accountCollapseToggle()"
          >
            <div
              :class="
                isAccountCollapseActive &&
                'text-white text-capitalize fs-2 fw-bold d-flex align-items-center ml-2 coustom-sidebar-width'
              "
              id="headingThree"
            >
              <p
                class="text-capitalize fs-2 fw-bold d-flex align-items-center ml-2 coustom-sidebar-width"
              >
                <i class="bi bi-clock-history mr-1 text-white"></i>
                <span v-bind:class="'sidebar-item text-white add-width-span'"
                  >Recharge History</span
                >
                <i
                  v-show="!isAccountCollapseOpen"
                  class="bi bi-chevron-down text-white"
                  aria-hidden="true"
                ></i>
                <i
                  v-show="isAccountCollapseOpen"
                  class="bi bi-chevron-up text-white"
                  aria-hidden="true"
                ></i>
              </p>
            </div>
          </div>
          <!-- Recharge Hestory Drowp Down Options  -->
          <div
            class=""
            v-show="(isOpenRechargeHistoryMenu(), isAccountCollapseOpen)"
          >
            <router-link to="/admin/pendingrequest" class="text-white">
              <div
                class="coustom-add-width-sidebar-menu"
                :class="{
                  'active-menu': $route.path === '/admin/pendingrequest',
                }"
              >
                <div>
                  <p
                    class="text-capitalize fs-2 fw-bold d-flex align-items-center ml-2 coustom-sidebar-width pl-2"
                    :class="{
                      'active-menu': $route.path === '/admin/pendingrequest',
                    }"
                  >
                    <i class="bi bi-hourglass-split mr-1 text-white"></i>
                    <span v-bind:class="'sidebar-item'">Pending Recharge</span>
                  </p>
                </div>
              </div>
            </router-link>

            <router-link to="/admin/acceptrequest" class="text-white">
              <div
                class="coustom-add-width-sidebar-menu"
                :class="{
                  'active-menu': $route.path === '/admin/acceptrequest',
                }"
              >
                <div>
                  <p
                    class="text-capitalize fs-2 fw-bold d-flex align-items-center ml-2 coustom-sidebar-width pl-2"
                    :class="{
                      'active-menu': $route.path === '/admin/acceptrequest',
                    }"
                  >
                    <i class="bi bi-check2-circle mr-1 text-white"></i>
                    <span v-bind:class="'sidebar-item'">Accept Recharge</span>
                  </p>
                </div>
              </div>
            </router-link>

            <router-link to="/admin/rejectrequest" class="text-white">
              <div
                class="coustom-add-width-sidebar-menu"
                :class="{
                  'active-menu': $route.path === '/admin/rejectrequest',
                }"
              >
                <div>
                  <p
                    class="text-capitalize fs-2 fw-bold d-flex align-items-center ml-2 coustom-sidebar-width pl-2"
                    :class="{
                      'active-menu': $route.path === '/admin/rejectrequest',
                    }"
                  >
                    <i class="bi bi-x-circle mr-1 text-white"></i>
                    <span v-bind:class="'sidebar-item'">Reject Recharge</span>
                  </p>
                </div>
              </div>
            </router-link>
          </div>

          <!-- Withdrawal Hestory Drowp Down -->
          <div
            class="coustom-add-width-sidebar-menu"
            @pointerdown="iswithdrawalCollapseActive == true"
            @pointerup="withdrawalCollapseToggle()"
          >
            <div
              :class="
                iswithdrawalCollapseActive &&
                'text-white text-capitalize fs-2 fw-bold d-flex align-items-center ml-2 coustom-sidebar-width'
              "
              id="headingThree"
            >
              <p
                class="text-capitalize fs-2 fw-bold d-flex align-items-center ml-2 coustom-sidebar-width"
              >
                <i class="bi bi-wallet-fill mr-1 text-white"></i>
                <span v-bind:class="'sidebar-item text-white add-width-span'"
                  >Withdrawal History</span
                >
                <i
                  v-show="!iswithdrawalCollapseOpen"
                  class="bi bi-chevron-down text-white"
                  aria-hidden="true"
                ></i>
                <i
                  v-show="iswithdrawalCollapseOpen"
                  class="bi bi-chevron-up text-white"
                  aria-hidden="true"
                ></i>
              </p>
            </div>
          </div>
          <!-- Withdrawal Hestory Drowp Down Options  -->
          <div
            class=""
            v-show="(isOpenwithdrawalHistoryMenu(), iswithdrawalCollapseOpen)"
          >
            <router-link
              to="/admin/adminrequestpenddingwithdrawal"
              class="text-white"
            >
              <div
                class="coustom-add-width-sidebar-menu"
                :class="{
                  'active-menu':
                    $route.path === '/admin/adminrequestpenddingwithdrawal',
                }"
              >
                <div>
                  <p
                    class="text-capitalize fs-2 fw-bold d-flex align-items-center ml-2 coustom-sidebar-width pl-2"
                    :class="{
                      'active-menu':
                        $route.path === '/admin/adminrequestpenddingwithdrawal',
                    }"
                  >
                    <i class="bi bi-hourglass-split mr-1 text-white"></i>
                    <span v-bind:class="'sidebar-item'"
                      >Withdrawal Pendding</span
                    >
                  </p>
                </div>
              </div>
            </router-link>

            <router-link to="/admin/adminacceptwithdrawal" class="text-white">
              <div
                class="coustom-add-width-sidebar-menu"
                :class="{
                  'active-menu': $route.path === '/admin/adminacceptwithdrawal',
                }"
              >
                <div>
                  <p
                    class="text-capitalize fs-2 fw-bold d-flex align-items-center ml-2 coustom-sidebar-width pl-2"
                    :class="{
                      'active-menu':
                        $route.path === '/admin/adminacceptwithdrawal',
                    }"
                  >
                    <i class="bi bi-check2-circle mr-1 text-white"></i>
                    <span v-bind:class="'sidebar-item'">Withdrawal Accept</span>
                  </p>
                </div>
              </div>
            </router-link>

            <router-link to="/admin/adminrejectwithdrawal" class="text-white">
              <div
                class="coustom-add-width-sidebar-menu"
                :class="{
                  'active-menu': $route.path === '/admin/adminrejectwithdrawal',
                }"
              >
                <div>
                  <p
                    class="text-capitalize fs-2 fw-bold d-flex align-items-center ml-2 coustom-sidebar-width pl-2"
                    :class="{
                      'active-menu':
                        $route.path === '/admin/adminrejectwithdrawal',
                    }"
                  >
                    <i class="bi bi-x-circle mr-1 text-white"></i>
                    <span v-bind:class="'sidebar-item'">Withdrawal Reject</span>
                  </p>
                </div>
              </div>
            </router-link>
          </div>
          <router-link to="/admin/adminqueries" class="text-white">
            <div
              class="coustom-add-width-sidebar-menu"
              :class="{
                'active-menu': $route.path === '/admin/adminqueries',
              }"
            >
              <div>
                <p
                  class="text-capitalize fs-2 fw-bold d-flex align-items-center ml-2 coustom-sidebar-width"
                  :class="{
                    'active-menu': $route.path === '/admin/adminqueries',
                  }"
                >
                  <i class="bi bi-shield-fill-exclamation mr-1 text-white"></i>
                  <span v-bind:class="'sidebar-item'">Queries</span>
                </p>
              </div>
            </div>
          </router-link>
          <!-- Setting Drop Down  -->
          <div
            class="coustom-add-width-sidebar-menu"
            @pointerdown="issettingCollapseActive == true"
            @pointerup="settingCollapseToggle()"
          >
            <div
              :class="
                issettingCollapseActive &&
                'text-white text-capitalize fs-2 fw-bold d-flex align-items-center ml-2 coustom-sidebar-width'
              "
              id="headingThree"
            >
              <p
                class="text-capitalize fs-2 fw-bold d-flex align-items-center ml-2 coustom-sidebar-width"
              >
                <i class="bi bi-gear mr-1 text-white"></i>
                <span v-bind:class="'sidebar-item text-white add-width-span'"
                  >Setting</span
                >
                <i
                  v-show="!issettingCollapseOpen"
                  class="bi bi-chevron-down text-white"
                  aria-hidden="true"
                ></i>
                <i
                  v-show="issettingCollapseOpen"
                  class="bi bi-chevron-up text-white"
                  aria-hidden="true"
                ></i>
              </p>
            </div>
          </div>
          <!-- Setting Hestory Drowp Down Options  -->
          <div
            class=""
            v-show="(isOpenSiteSettingyMenu(), issettingCollapseOpen)"
          >
            <router-link to="/admin/setting" class="text-white">
              <div
                class="coustom-add-width-sidebar-menu"
                :class="{
                  'active-menu': $route.path === '/admin/setting',
                }"
              >
                <div>
                  <p
                    class="text-capitalize fs-2 fw-bold d-flex align-items-center ml-2 coustom-sidebar-width pl-2"
                    :class="{
                      'active-menu': $route.path === '/admin/setting',
                    }"
                  >
                    <i class="bi bi-sliders mr-1 text-white"></i>
                    <span v-bind:class="'sidebar-item'">Site Setting</span>
                  </p>
                </div>
              </div>
            </router-link>

            <router-link to="/admin/adminnotification" class="text-white">
              <div
                class="coustom-add-width-sidebar-menu"
                :class="{
                  'active-menu': $route.path === '/admin/adminnotification',
                }"
              >
                <div>
                  <p
                    class="text-capitalize fs-2 fw-bold d-flex align-items-center ml-2 coustom-sidebar-width pl-2"
                    :class="{
                      'active-menu': $route.path === '/admin/adminnotification',
                    }"
                  >
                    <i class="bi bi-app-indicator mr-1 text-white"></i>
                    <span v-bind:class="'sidebar-item'">Notification</span>
                  </p>
                </div>
              </div>
            </router-link>

            <router-link to="/admin/adminmanagebanner" class="text-white">
              <div
                class="coustom-add-width-sidebar-menu"
                :class="{
                  'active-menu': $route.path === '/admin/adminmanagebanner',
                }"
              >
                <div>
                  <p
                    class="text-capitalize fs-2 fw-bold d-flex align-items-center ml-2 coustom-sidebar-width pl-2"
                    :class="{
                      'active-menu': $route.path === '/admin/adminmanagebanner',
                    }"
                  >
                    <i class="bi bi-collection mr-1 text-white"></i>
                    <span v-bind:class="'sidebar-item'">Manage Banner</span>
                  </p>
                </div>
              </div>
            </router-link>
            <router-link to="/admin/adminsetlaveluser" class="text-white">
              <div
                class="coustom-add-width-sidebar-menu"
                :class="{
                  'active-menu': $route.path === '/admin/adminsetlaveluser',
                }"
              >
                <div>
                  <p
                    class="text-capitalize fs-2 fw-bold d-flex align-items-center ml-2 coustom-sidebar-width pl-2"
                    :class="{
                      'active-menu': $route.path === '/admin/adminsetlaveluser',
                    }"
                  >
                    <i class="bi bi-arrow-up mr-1 text-white"></i>

                    <span v-bind:class="'sidebar-item'">Set Level User</span>
                  </p>
                </div>
              </div>
            </router-link>
            <router-link to="/admin/adminmanageproduct" class="text-white">
              <div
                class="coustom-add-width-sidebar-menu"
                :class="{
                  'active-menu': $route.path === '/admin/adminmanageproduct',
                }"
              >
                <div>
                  <p
                    class="text-capitalize fs-2 fw-bold d-flex align-items-center ml-2 coustom-sidebar-width pl-2"
                    :class="{
                      'active-menu':
                        $route.path === '/admin/adminmanageproduct',
                    }"
                  >
                    <i class="bi bi-bag mr-1 text-white"></i>
                    <span v-bind:class="'sidebar-item'">Manage Products</span>
                  </p>
                </div>
              </div>
            </router-link>
          </div>
          <div class="coustom-add-width-sidebar-menu" v-on:click="showModel">
            <div class="" v-on:click="showModel">
              <div class="text-white" v-on:click="showModel">
                <p
                  class="text-capitalize fs-2 fw-bold d-flex align-items-center ml-2 coustom-sidebar-width"
                  v-on:click="showModel"
                >
                  <i class="bi bi-box-arrow-left mr-1 text-white"></i>
                  <span v-bind:class="'sidebar-item'">Logout</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="notification-modals" v-if="isLogOutModalVisible">
    <div class="modal-containers">
      <div class="modal-headers">
        <b>Are You Sure You Want To Logout?</b>
      </div>
      <div class="modal-footers">
        <button class="btn btn-comman active" @click="hideModal">Cancel</button>
        <button class="btn btn-comman active" @click="logout(), hideModal()">
          Yes
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "SaidManuBar",
  data() {
    let isAccountCollapseActive = ref(false);
    let isAccountCollapseOpen = ref(false);
    let iswithdrawalCollapseOpen = ref(false);
    let iswithdrawalCollapseActive = ref(false);
    let issettingCollapseActive = ref(false);
    let issettingCollapseOpen = ref(false);
    let isLogOutModalVisible = ref(false);
    const accountCollapseToggle = async () => {
      isAccountCollapseOpen.value = !isAccountCollapseOpen.value;
      isAccountCollapseActive.value = false;
      if (this.isAccountCollapseOpen == true) {
        this.iswithdrawalCollapseOpen = false;
        this.issettingCollapseOpen = false;
      }
    };

    const withdrawalCollapseToggle = async () => {
      iswithdrawalCollapseOpen.value = !iswithdrawalCollapseOpen.value;
      iswithdrawalCollapseActive.value = false;
      if (this.iswithdrawalCollapseOpen == true) {
        this.isAccountCollapseOpen = false;
        this.issettingCollapseOpen = false;
      }
    };
    const settingCollapseToggle = async () => {
      issettingCollapseOpen.value = !issettingCollapseOpen.value;
      issettingCollapseActive.value = false;
      if (this.issettingCollapseOpen == true) {
        this.isAccountCollapseOpen = false;
        this.iswithdrawalCollapseOpen = false;
      }
    };
    return {
      issidebaropen: true,
      accountCollapseToggle,
      isAccountCollapseActive,
      isAccountCollapseOpen,
      withdrawalCollapseToggle,
      iswithdrawalCollapseOpen,
      iswithdrawalCollapseActive,
      settingCollapseToggle,
      issettingCollapseOpen,
      issettingCollapseActive,
      isLogOutModalVisible,
    };
  },
  created() {
    this.router = this.$router;
  },
  methods: {
    logout() {
      const userInfo = localStorage.getItem("user-info");
      if (!userInfo) {
        alert("Admin Not Found");
      } else {
        localStorage.removeItem("user-info");
        this.router.push({ path: "/admin" });
      }
    },
    hideModal() {
      this.isLogOutModalVisible = false;
      document.body.classList.remove("modal-open");
    },
    showModel() {
      this.isLogOutModalVisible = true;
      document.body.classList.add("modal-open");
    },

    isOpenRechargeHistoryMenu() {
      if (
        this.$route.path === "/admin/pendingrequest" ||
        this.$route.path === "/admin/acceptrequest" ||
        this.$route.path === "/admin/rejectrequest"
      ) {
        this.isAccountCollapseOpen = true;
      }
    },
    isOpenwithdrawalHistoryMenu() {
      if (
        this.$route.path === "/admin/adminrequestpenddingwithdrawal" ||
        this.$route.path === "/admin/adminacceptwithdrawal" ||
        this.$route.path === "/admin/adminrejectwithdrawal"
      ) {
        this.iswithdrawalCollapseOpen = true;
      }
    },
    isOpenSiteSettingyMenu() {
      if (
        this.$route.path === "/admin/setting" ||
        this.$route.path === "/admin/adminnotification" ||
        this.$route.path === "/admin/adminmanagebanner" ||
        this.$route.path === "/admin/adminsetlaveluser" ||
        this.$route.path === "/admin/adminmanageproduct"
      ) {
        this.issettingCollapseOpen = true;
      }
    },
  },
};
</script>

<style>
@import "../../assets/css/admin-style/admin-sidebar.css";

.notification-modals {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 9999;
}

.modal-containers {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-headers {
  font-weight: bold;
  margin-bottom: 10px;
}

.modal-footers {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
</style>
