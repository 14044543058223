<template>
  <div>
    <Navbar />

    <!-- sidenav -->
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-2 col-md-3 col-sm-4 col-12 pl-0">
          <SaidManuBar />
        </div>
        <div
          class="container-fluid col-lg-10 col-md-9 col-sm-8 col-12 p-1 bg-white bg-gradient"
        >
          <h5 class="p-1 mr-0 ml-0 m-1 setresultgame-title">
            Set Result Of A Game
          </h5>
          <div
            class="setresultgame-result-box col-12 p-1 mr-0 ml-0 m-1 text-white add-bg-color-sub-header"
          >
            <div class="row">
              <div class="col-lg-2 col-12 responsive-bottom-space">
                Count Down:

                <div class="bot_ol">
                  <button
                    :class="continueClass"
                    style="
                      padding: 8px 15px;
                      font-size: 14px;
                      border-radius: 2px;
                      border: 0;
                    "
                    v-if="countcontinue"
                    v-on:click="(countcontinue = false), (continueClass = '')"
                  >
                    Continue
                  </button>
                  <div class="countdown" v-if="!countcontinue">
                    <div class="van-count-down">
                      <span class="span set-bg">{{
                        countDownMinute > 9
                          ? countDownMinute.toString().charAt(0)
                          : 0
                      }}</span>
                      <span class="span set-bg">{{
                        countDownMinute > 9
                          ? countDownMinute.toString().charAt(1)
                          : countDownMinute.toString().charAt(0)
                      }}</span>
                      <span>:</span>
                      <span class="span set-bg">{{
                        countDownSecond.toString().charAt(0)
                          ? countDownSecond.toString().charAt(0)
                          : 0
                      }}</span>
                      <span class="span set-bg">{{
                        countDownSecond.toString().charAt(1)
                          ? countDownSecond.toString().charAt(1)
                          : 0
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-12 responsive-bottom-space">
                Active Period Id:
                <div>{{ gameid }}</div>
              </div>
              <div class="col-lg-4 col-12 responsive-bottom-space d-none">
                <div class="row">
                  <div class="col-auto">
                    <div>Parity</div>
                    <div>
                      <input
                        type="radio"
                        id="parity"
                        value="parity"
                        name="check"
                        @click="getTabsValue('parity')"
                      />
                    </div>
                  </div>
                  <div class="col-auto">
                    <div>Sapre</div>
                    <div>
                      <input
                        type="radio"
                        id="sapre"
                        value="sapre"
                        name="check"
                        @click="getTabsValue('sapre')"
                      />
                    </div>
                  </div>
                  <div class="col-auto">
                    <div>Bcone</div>
                    <div>
                      <input
                        type="radio"
                        id="bcone"
                        value="bcone"
                        name="check"
                        @click="getTabsValue('bcone')"
                      />
                    </div>
                  </div>
                  <div class="col-auto">
                    <div>Emerd</div>
                    <div>
                      <input
                        type="radio"
                        id="emerd"
                        value="emerd"
                        name="check"
                        @click="getTabsValue('emerd')"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-12 responsive-bottom-space d-none">
                <div>Do you want manual result ?</div>
                <button
                  name="resultButton"
                  v-bind:class="
                    isyesnoopen === true
                      ? 'switchButton bg-dark'
                      : 'switchButton text-dark'
                  "
                  value="yes"
                  @click="
                    {
                      isyesnoopen = !this.isyesnoopen;
                    }
                  "
                >
                  Yes
                </button>
                <button
                  name="resultButton"
                  v-bind:class="
                    isyesnoopen === true
                      ? 'switchButton text-dark'
                      : 'switchButton bg-dark'
                  "
                  value="no"
                  @click="
                    {
                      isyesnoopen = !this.isyesnoopen;
                    }
                  "
                >
                  No
                </button>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table setresultgame-table">
              <thead>
                <tr>
                  <th scope="col" class="font-weight-bold text-center">
                    Result
                  </th>
                  <th scope="col" class="font-weight-bold text-center">
                    Number
                  </th>
                  <th scope="col" class="font-weight-bold text-center">
                    No. of User
                  </th>
                  <th scope="col" class="font-weight-bold text-center">
                    Total Win Amount
                  </th>
                  <th scope="col" class="font-weight-bold text-center">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in data" :key="item._id">
                  <td class="text-center">
                    <div v-html="item.color"></div>
                  </td>
                  <td class="text-center">{{ item.number }}</td>
                  <td class="text-center">{{ item.uniqueUserIds?.length }}</td>
                  <td class="text-center">
                    {{ item.total?.toFixed(2) }}
                  </td>
                  <!-- <td>{{ item.total }}</td> -->
                  <td class="text-center">
                    <input
                      type="radio"
                      :value="item.number"
                      v-model="getNumbers"
                      @click="
                        getResultNumbers(item.number),
                          updateResultStatus(item.number)
                      "
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- pagination -->
            <nav aria-label="Page navigation">
              <ul
                class="setresultgame-pagination pagination justify-content-end"
              >
                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                  <button class="page-link" @click="prevPage">&laquo;</button>
                </li>
                <li class="page-item">
                  <button
                    class="page-link"
                    @click="gotoPage(currentPage - 1)"
                    v-if="currentPage > 1"
                  >
                    {{ currentPage - 1 }}
                  </button>
                </li>
                <li
                  class="page-item active"
                  v-if="currentPage === 1 || currentPage !== totalPages"
                >
                  <button class="page-link" @click="gotoPage(currentPage)">
                    {{ currentPage }}
                  </button>
                </li>
                <li class="page-item">
                  <button
                    class="page-link"
                    @click="gotoPage(currentPage + 1)"
                    v-if="
                      currentPage < totalPages && currentPage + 1 !== totalPages
                    "
                  >
                    {{ currentPage + 1 }}
                  </button>
                </li>
                <li class="page-item">
                  <button class="page-link" v-if="totalPages > 2">....</button>
                </li>
                <li
                  class="page-item"
                  :class="currentPage === totalPages && 'active'"
                >
                  <button
                    class="page-link"
                    @click="gotoPage(totalPages)"
                    v-if="totalPages > 2"
                  >
                    {{ totalPages }}
                  </button>
                </li>
                <li
                  class="page-item"
                  :class="{ disabled: currentPage === totalPages }"
                >
                  <button class="page-link" @click="nextPage">&raquo;</button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- loader -->
  <div class="loadings" v-show="loader" style="/* display: none; */">
    <div
      class="v-dialog v-dialog--persistent"
      style="width: 300px; display: block"
    >
      <div class="v-card v-sheet theme--dark teals-loadings">
        <div class="v-card__text">
          <span>Loading</span>
          <div
            role="progressbar"
            aria-valuemin="0"
            aria-valuemax="100"
            class="v-progress-linear mb-0"
            style="height: 7px"
          >
            <div
              class="v-progress-linear__background white"
              style="height: 7px; opacity: 0.3; width: 100%"
            ></div>
            <div class="v-progress-linear__bar">
              <div
                class="v-progress-linear__bar__indeterminate v-progress-linear__bar__indeterminate--active"
              >
                <div
                  class="v-progress-linear__bar__indeterminate long white"
                ></div>
                <div
                  class="v-progress-linear__bar__indeterminate short white"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Navbar from "./Navbar.vue";
import SaidManuBar from "./SideMenuBar.vue";
import io from "socket.io-client";
export default {
  name: "SetResultGame",
  components: {
    Navbar,
    SaidManuBar,
  },
  data() {
    // let socket = io("http://localhost:3004");
    return {
      countinue: false,
      currentPage: 1,
      totalPages: 0,
      pageSize: 10,
      data: [],
      gameid: "",
      tabsValue: null,
      isyesnoopen: true,
      getNumbers: "",
      Isactive: false,
      continueClass: "",
      countcontinue: false,
      countDownMinute: 0,
      countDownSecond: 0,
      socket: null,
      reciveData: null,
      reciveDataValues: null,
      userID: [],
      loader: false,
    };
  },
  mounted() {
    this.socket = io(process.env.VUE_APP_SOCAT_URL);
    // this.socket.connect();
    this.gameID();
    this.intervalSet();
    this.showTimer();
    const user = localStorage.getItem("user-info");
    if (!user) {
      this.$router.push({ name: "AdminLogin" });
    }
  },
  methods: {
    async gameID() {
      try {
        const response = await axios.get(`/gameid`, {});
        if (response.data.success === true) {
          this.gameid = await response?.data?.data?.gameid;
          this.setResult();
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    },

    async setResult() {
      try {
        this.loader = true;
        let response = "";
        if (this.gameid && this.gameid != "") {
          response = await axios.post(`admin/setresulgame`, {
            actiontype: "getdata",
            tab: "parity",
            periodid: this.gameid,
          });
          this.data = response.data.data;
          //this.socket.emit("bate", response.data.data);
          for (const iterator of this.data) {
            if (iterator.status) {
              this.getNumbers = iterator.number;
              this.Isactive = true;
            }
          }
          this.socket.on("bate", (data1) => {
            // console.log("value data :- ", data1);
            // console.log(this.data[data1.value]);
            console.log(data1);

            // if (!this.data[data1.value].uniqueUserIds.includes(data1.userid)) {
            //   this.data[data1.value].uniqueUserIds.push(data1.userid);
            // }
            // if (data1.type == "number") {
            //   // this.data[data1.value].total += (data1.amount / 100) * 95;
            //   this.data[data1.value].total +=
            //     this.truncate((data1.amount - (data1.amount / 100) * 95* 9,2);
            // } else {
            if (data1.value.toLowerCase() == "violet") {
              // violet
              this.data[0].total += this.truncate(
                (data1.amount / 100) * 95 * 4.5,
                2
              );
              this.data[5].total += this.truncate(
                (data1.amount / 100) * 95 * 4.5,
                2
              );

              if (!this.data[0].uniqueUserIds.includes(data1.userid)) {
                this.data[0].uniqueUserIds.push(data1.userid);
              }
              if (!this.data[5].uniqueUserIds.includes(data1.userid)) {
                this.data[5].uniqueUserIds.push(data1.userid);
              }

              // red
            } else if (data1.value.toLowerCase() == "red") {
              this.data[2].total += this.truncate(
                (data1.amount / 100) * 95 * 2,
                2
              );
              this.data[4].total += this.truncate(
                (data1.amount / 100) * 95 * 2,
                2
              );
              this.data[6].total += this.truncate(
                (data1.amount / 100) * 95 * 2,
                2
              );
              this.data[8].total += this.truncate(
                (data1.amount / 100) * 95 * 2,
                2
              );
              this.data[0].total += this.truncate(
                (data1.amount / 100) * 95 * 1.5,
                2
              );

              if (!this.data[0].uniqueUserIds.includes(data1.userid)) {
                this.data[0].uniqueUserIds.push(data1.userid);
              }
              if (!this.data[2].uniqueUserIds.includes(data1.userid)) {
                this.data[2].uniqueUserIds.push(data1.userid);
              }
              if (!this.data[4].uniqueUserIds.includes(data1.userid)) {
                this.data[4].uniqueUserIds.push(data1.userid);
              }
              if (!this.data[6].uniqueUserIds.includes(data1.userid)) {
                this.data[6].uniqueUserIds.push(data1.userid);
              }
              if (!this.data[8].uniqueUserIds.includes(data1.userid)) {
                this.data[8].uniqueUserIds.push(data1.userid);
              }
            } else if (data1.value.toLowerCase() == "green") {
              this.data[1].total += this.truncate(
                (data1.amount / 100) * 95 * 2,
                2
              );
              this.data[3].total += this.truncate(
                (data1.amount / 100) * 95 * 2,
                2
              );
              this.data[7].total += this.truncate(
                (data1.amount / 100) * 95 * 2,
                2
              );
              this.data[9].total += this.truncate(
                (data1.amount / 100) * 95 * 2,
                2
              );
              this.data[5].total += this.truncate(
                (data1.amount / 100) * 95 * 1.5,
                2
              );
              if (!this.data[1].uniqueUserIds.includes(data1.userid)) {
                this.data[1].uniqueUserIds.push(data1.userid);
              }
              if (!this.data[3].uniqueUserIds.includes(data1.userid)) {
                this.data[3].uniqueUserIds.push(data1.userid);
              }
              if (!this.data[7].uniqueUserIds.includes(data1.userid)) {
                this.data[7].uniqueUserIds.push(data1.userid);
              }
              if (!this.data[9].uniqueUserIds.includes(data1.userid)) {
                this.data[9].uniqueUserIds.push(data1.userid);
              }
              if (!this.data[5].uniqueUserIds.includes(data1.userid)) {
                this.data[5].uniqueUserIds.push(data1.userid);
              }
            } else if (
              ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(
                data1.value
              )
            ) {
              this.data[data1.value].total += this.truncate(
                (data1.amount / 100) * 95 * 9,
                2
              );
              if (
                !this.data[data1.value].uniqueUserIds.includes(data1.userid)
              ) {
                this.data[data1.value].uniqueUserIds.push(data1.userid);
              }
            }
            // }
          });
          this.loader = false;
        }
      } catch (error) {
        alert(error);
      }
    },
    truncate(value, decimals) {
      // Helper function to truncate decimal places
      const multiplier = Math.pow(10, decimals);
      return Math.trunc(value * multiplier) / multiplier;
    },
    async updateResultStatus(number) {
      try {
        this.loader = true;
        const response = await axios.post(`/admin/selectresultnumber`, {
          number: number,
        });
        this.data = response.data;
        console.log("updateResultStatus", this.data);
        console.log("updateResultStatus", response);
        this.setResult();
        this.loader = false;
      } catch (error) {
        console.error("Error fetching data:", error);
        alert("Error fetching data");
      }
    },
    showTimer() {
      var countDownDate = Date.parse(new Date()) / 1e3;
      var distance = 180 - (countDownDate % 180);
      var i = distance / 60,
        n = distance % 60;
      this.countDownMinute = Math.floor(i);
      this.countDownSecond = ("0" + Math.floor(n)).slice(-2);

      if (distance === 180) {
        this.countcontinue = true;
        this.continueClass = "Disabled";
      }

      if (distance === 176) {
        this.continueClass = "continue";
      }

      if (distance === 174) {
        this.countcontinue = false;
        this.continueClass = "";
        window.location.reload();
      }
    },

    intervalSet() {
      setInterval(() => {
        this.showTimer();
      }, 1e3);
    },

    getTabsValue(tabName) {
      this.tabsValue = tabName;
      console.log("Tabs Name ", this.tabsValue);
    },
    getResultNumbers(number) {
      if (this.Isactive) {
        if (this.getNumbers === number) {
          this.Isactive = false;
          this.getNumbers = null;
        } else {
          this.Isactive = true;
          this.getNumbers = number;
        }
      } else {
        this.Isactive = true;
        this.getNumbers = number;
      }
      console.log(this.getNumbers);
    },

    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    gotoPage(page) {
      this.currentPage = page;
    },
  },
};
</script>
