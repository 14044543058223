<template>
  <!-- <link rel="stylesheet" :href="'/css/redEnvelope.css'" /> -->

  <div class="appHeader1 redenvelope-banner">
    <div class="left">
      <a href="#" v-on:click="router.go(-1)" class="icon goBack">
        <img :src="'/images/left.png'" />
      </a>
      <div class="pageTitle">Red Envelopes</div>
    </div>
    <div class="rednvelop-vector">
      <img :src="'/images/rednvelop-vector.png'" alt="">
    </div>
  </div>
  <div class="layout-content">
    <div class="envelope-context">
      <div class="top custom-box">
        <div class="redenvelopes_box">
          <p class="title-surprice">Surprise</p>
          <div class="img_box">
            <div class="hb_img_box">
              <div class="price">
                ₹ <span>{{ amount }}</span>
              </div>
              <img :src="'/images/red-001.png'">
            </div>
          </div>
          <router-link to="/login">
            <div class="btn_box">
              <a :href="loginroute">Continue</a>
            </div>
          </router-link>
          <div class="toast-content" v-show="isErrorShow">
            <div class="toast-message">{{ errorMessage }}</div>
          </div>
          <h2 class="error_msg"></h2>
        </div>
      </div>
    </div>
  </div>
  <PageReload />
</template>

    
<script>
import { useRouter } from "vue-router";
import axios from "axios";
import { ref } from "vue";
import PageReload from "../components/PageReload.vue";

export default {
  components: {

    PageReload,
  },
  props: {
    toggleLoader: { type: Function },
  },
  setup() {
    // useMeta({ title: "RedEnvelope Page" });
    const router = useRouter();

    const isErrorShow = ref(false);
    const errorMessage = ref("");
    const amount = ref(0);
    const loginroute = location.host + '/#/login';
    var routecode = ref("0");
    routecode = router.currentRoute._value.query.code;
    const getProductData = async () => {
      axios
        .get(
          "/get-envelope/" +
          routecode.value,
          {}
        )
        .then((response) => {
          if (response.data.success === true) {
            setErrorMessage(response.data.message);
            amount.value = response.data.data.amount;
          } else {
            setErrorMessage(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const setErrorMessage = (value) => {
      errorMessage.value = value;
      isErrorShow.value = true;
      setInterval(() => {
        errorMessage.value = "";
        isErrorShow.value = false;
      }, 2000);
    };

    if (!localStorage.getItem("authToken")) {
      setErrorMessage('Not valid');
    } else {
      getProductData();
    }

    return {
      router,
      isErrorShow,
      errorMessage,
      setErrorMessage,
      getProductData,
      amount,
      loginroute,
    };
  },
};
</script>

<style scoped>

/* @import "../assets/css/redEnvelope.css"; */


@media only screen and (max-width:480px) {
  .pagination {
    padding: 0 15px !important;
  }

  .choose_page {
    padding: 10px 15px !important;
  }
}
</style>