<template>
  <div
    class="container-fluid d-flex justify-content-center align-items-center min-vh-100 add-bg-secondary"
  >
 
    <div class="col-md-6 col-lg-4">
      <h2 class="card-title text-center mb-4 w-100%">Adminsuit Login</h2>
      <div class="card add-shadow">
        <div class="card-body p-4">
          <p class="card-title text-center mb-4 w-100%">Sign in to start your session</p>
          <form @submit.prevent="submitForm">
            <div class="form-group input-group">
              <!-- <label for="username">
                <i class="bi bi-person-fill"></i>
                 Username:
              </label> -->
              <input
                type="text"
                class="form-control border border-right-0 p-1"
                placeholder="Username"
                id="username"
                v-model="username"
                autocomplete="off"
                autocapitalize="none"
                autocorrect="off"
                required
              />
              <div class="input-group-append">
                   <span class="input-group-text bg-transparent border-left-0 bi bi-person-fill"></span>
            </div> 
            </div>
            <div class="form-group input-group">
              <!-- <label for="password">
                <i class="bi bi-lock-fill"></i> Password:
              </label> -->
              <input
                type="password"
                class="form-control border border-right-0 p-1"
                id="password"
                placeholder="Password"
                v-model="password"
                autocomplete="new-password"
                autocapitalize="none"
                autocorrect="off"
                required
              />
              <div class="input-group-append">
                   <span class="input-group-text bg-transparent border-left-0 bi bi-lock-fill"></span>
            </div>
            </div>
            <div class="d-grid gap-2">
              <button type="submit" class="Adminlogin-btn">Sing In</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    async submitForm() {
      try {
        const result = await axios.post(`/adminlogin`, {
          name: this.username,
          password: this.password,
        });
        localStorage.setItem("user-info", JSON.stringify(result.data.token));
        if (result && result.data && result.data.success === true) {
          this.$router.push({ name: "AdminDesktop" });
        } else {
          alert("Credentials do not match");
        }
      } catch (error) {
        alert("Credentials do not match");
        console.log(error);
      }
    },
  },
};
</script>

<style>
.add-shadow {
  box-shadow: 0 4px 40px -8px rgba(0, 0, 0, 0.2), 0 2px 2 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.add-bg-secondary {
  background-color: rgb(210 214 222);
  position: fixed;
}
</style>
