<template>
  <div>
    <Navbar />

    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-2 col-md-3 col-sm-4 col-12 pl-0">
          <SaidManuBar />
        </div>
        <div
          class="col-lg-10 col-md-9 col-sm-8 col-12 p-1 bg-white bg-gradient pending-recharge-content"
        >
          <h3 class="p-1 mr-0 add-bg-color-sub-header pending-recharge-title">
            Pending Recharge
          </h3>
          <nav class="navbar pr-0 pl-0">
            <div
              class="pending-recharge-search-box d-flex ml-auto"
              role="search"
            >
              <label>Search:</label>
              <input
                class="form-control p-1"
                v-model="searchTerms"
                @input="search()"
                type="search"
                aria-label="Search"
                id="input"
              />
            </div>
          </nav>
          <div class="table-responsive table-responsive-sm">
            <table class="table pending-recharge-table" v-if="loader === false">
              <thead>
                <tr>
                  <th scope="col" class="font-weight-bold text-center">
                    <span>Sr.No.</span>
                  </th>
                  <th
                    scope="col"
                    class="font-weight-bold text-center add-table-data-in-center-width"
                  >
                    <i
                      class="bi bi-arrow-down-up mr-1 cursor-pointer"
                      @click="sort('userid')"
                    ></i
                    >User Mobile
                  </th>
                  <th
                    scope="col"
                    class="font-weight-bold text-center add-table-data-in-center-width"
                  >
                    <i
                      class="bi bi-arrow-down-up mr-1 cursor-pointer"
                      @click="sort('amount')"
                    ></i
                    >Amount
                  </th>
                  <th
                    scope="col"
                    class="font-weight-bold text-center add-table-data-in-center-width"
                  >
                    <i
                      class="bi bi-arrow-down-up mr-1 cursor-pointer add-table-data-in-center-width"
                      @click="sort('orderid')"
                    ></i
                    >Order ID
                  </th>
                  <th
                    scope="col"
                    class="font-weight-bold text-center add-table-data-in-center-width"
                  >
                    <i
                      class="bi bi-arrow-down-up mr-1 cursor-pointer"
                      @click="sort('wallet')"
                    ></i
                    >Wallet
                  </th>
                  <th
                    scope="col"
                    class="font-weight-bold text-center add-table-data-in-center-width"
                  >
                    <i
                      class="bi bi-arrow-down-up mr-1 cursor-pointer"
                      @click="sort('createdate')"
                    ></i
                    >Req. Date
                  </th>
                  <th scope="col" class="font-weight-bold text-center">
                    Success
                  </th>
                  <th scope="col" class="font-weight-bold text-center">
                    Reject
                  </th>
                </tr>
              </thead>
              <tbody v-if="data?.length > 0">
                <tr v-for="(item, index) in data" :key="item._id">
                  <td scope="col" class="text-center">{{ index + 1 }}</td>
                  <td scope="col" class="text-center">
                    {{ item?.usermobilenumber }}
                  </td>
                  <td scope="col" class="text-center">
                    {{ item?.amount?.toFixed(2) }}
                  </td>
                  <td scope="col" class="text-center">{{ item?.orderid }}</td>
                  <td scope="col" class="text-center">
                    {{ item?.wallet?.toFixed(2) }}
                  </td>
                  <td scope="col" class="text-center">
                    {{ new Date(item?.createdate).toLocaleDateString() }}
                  </td>

                  <td scope="col" class="text-center">
                    <button
                      class="btn btn-success m-0"
                      v-on:click="adminRechargeAccept(item._id)"
                    >
                      Accept
                    </button>
                  </td>
                  <td scope="col" class="text-center">
                    <button
                      class="btn btn-light m-0"
                      v-on:click="adminRechargeReject(item._id)"
                    >
                      Reject
                    </button>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="8" class="text-center">No Data Found</td>
                </tr>
              </tbody>
            </table>
          </div>
          <nav aria-label="Page navigation">
            <ul
              class="pending-recharge-pagination pagination justify-content-end"
            >
              <li class="page-item" :class="{ disabled: currentPage === 1 }">
                <button class="page-link" @click="prevPage">&laquo;</button>
              </li>
              <li class="page-item">
                <button
                  class="page-link"
                  @click="gotoPage(currentPage - 1)"
                  v-if="currentPage > 1"
                >
                  {{ currentPage - 1 }}
                </button>
              </li>
              <li
                class="page-item active"
                v-if="currentPage === 1 || currentPage !== totalPages"
              >
                <button class="page-link" @click="gotoPage(currentPage)">
                  {{ currentPage }}
                </button>
              </li>
              <li class="page-item">
                <button
                  class="page-link"
                  @click="gotoPage(currentPage + 1)"
                  v-if="
                    currentPage < totalPages && currentPage + 1 !== totalPages
                  "
                >
                  {{ currentPage + 1 }}
                </button>
              </li>
              <li class="page-item">
                <button class="page-link" v-if="totalPages > 2">....</button>
              </li>
              <li
                class="page-item"
                :class="currentPage === totalPages && 'active'"
              >
                <button
                  class="page-link"
                  @click="gotoPage(totalPages)"
                  v-if="totalPages > 2"
                >
                  {{ totalPages }}
                </button>
              </li>
              <li
                class="page-item"
                :class="{ disabled: currentPage === totalPages }"
              >
                <button class="page-link" @click="nextPage">&raquo;</button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
  <div v-bind:class="this.tost ? 'set-tost' : 'd-none'">
    {{ this.tost }}
  </div>
  <!-- loader -->
  <div class="loadings" v-show="loader" style="/* display: none; */">
    <div
      class="v-dialog v-dialog--persistent"
      style="width: 300px; display: block"
    >
      <div class="v-card v-sheet theme--dark teals-loadings">
        <div class="v-card__text">
          <span>Loading</span>
          <div
            role="progressbar"
            aria-valuemin="0"
            aria-valuemax="100"
            class="v-progress-linear mb-0"
            style="height: 7px"
          >
            <div
              class="v-progress-linear__background white"
              style="height: 7px; opacity: 0.3; width: 100%"
            ></div>
            <div class="v-progress-linear__bar">
              <div
                class="v-progress-linear__bar__indeterminate v-progress-linear__bar__indeterminate--active"
              >
                <div
                  class="v-progress-linear__bar__indeterminate long white"
                ></div>
                <div
                  class="v-progress-linear__bar__indeterminate short white"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Navbar from "./Navbar.vue";
import SaidManuBar from "./SideMenuBar.vue";

export default {
  name: "PendingRequest",

  data() {
    return {
      data: [],
      currentPage: 1,
      totalPages: 0,
      pageSize: 10,
      searchTerms: null,
      sortBy: null,
      sortOrder: 1,
      adminRechargeAcceptData: [],
      adminRechargeRejectData: [],
      tost: "",
      loader: false,
    };
  },

  components: {
    Navbar,
    SaidManuBar,
  },
  methods: {
    async fetchData() {
      try {
        this.loader = true;
        const response = await axios.post(`/admin/rechargepending`, {
          page: this.currentPage,
          pageSize: this.pageSize,
        });
        this.data = response.data.data;
        this.totalPages = response.data.totalPages;
        this.currentPage = response.data.currentPage;
        this.loader = false;
      } catch (error) {
        console.error("Error fetching data:", error);
        alert("Error fetching data");
      }
    },
    async adminRechargeAccept(_id) {
      try {
        const response = await axios.post(`/admin/adminrechargeaccept`, {
          _id,
        });
        this.adminRechargeAcceptData = await response.data.data;
        this.fetchData();
        setTimeout(() => {
          this.tost = "Recharge Accept Successfully";
        }, 1000);
        setTimeout(() => {
          this.tost = "";
        }, 5000);
      } catch (error) {
        console.log("Admin accept recharge internel server error", error);
      }
    },
    async adminRechargeReject(_id) {
      try {
        const response = await axios.post(`/admin/adminrechargereject`, {
          _id,
        });
        this.adminRechargeAcceptData = response.data.data;
        this.fetchData();
        setTimeout(() => {
          this.tost = "Recharge Reject Successfully";
        }, 1000);
        setTimeout(() => {
          this.tost = "";
        }, 5000);
      } catch (error) {
        console.log("Admin accept recharge internel server error", error);
      }
    },

    sort(field) {
      if (this.sortBy === field) {
        this.sortOrder *= -1;
      } else {
        this.sortBy = field;
        this.sortOrder = 1;
      }
      this.allUserData.sort((a, b) => {
        const valueA = this.getNestedPropertyValue(a, field);
        const valueB = this.getNestedPropertyValue(b, field);
        if (valueA < valueB) {
          return -1 * this.sortOrder;
        }
        if (valueA > valueB) {
          return 1 * this.sortOrder;
        }
        return 0;
      });
    },
    getNestedPropertyValue(obj, path) {
      const properties = path.split(".");
      let value = obj;
      for (const prop of properties) {
        value = value[prop];
        if (value === undefined) {
          return "";
        }
      }
      return value;
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchData();
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchData();
      }
    },
    gotoPage(page) {
      this.currentPage = page;
      this.fetchData();
    },
    async search() {
      try {
        if (this.searchTerms) {
          const response = await axios.post(`/admin/rechargepending`, {
            searchTerms: this.searchTerms,
          });
          this.data = response.data.data;
        } else {
          this.fetchData();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>
