<template>
  <div class="win">
    <div class="mine_top">
      <div class="mine_info">
        <p
          class="balance custom-font_size"
          style="color: #000; margin-bottom: 0 !important"
        >
          Available balance: ₹ {{ mineDashbordData.balance }}
        </p>
        <p
          class="active_users"
          style="color: #000; margin-block-start: 0 !important"
        >
          Total Withdrawal:₹ {{ mineDashbordData.totalwithdrawal }}
        </p>
        <div class="mine_info_btn">
          <div class="btn-r">
            <router-link to="/recharge">
              <button class="one_btn">Recharge</button>
            </router-link>
            <router-link to="/emerd">
              <button>Trend</button>
            </router-link>
          </div>
          <div class="refresh">
            <img
              :src="require('../../assets/images/refresh.png')"
              v-on:click="router.go(0)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useRouter } from "vue-router";
export default {
  props: {
    mineDashbordData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const router = useRouter();
    const refreshPage = () => {
      router.go(0);
    };

    return {
      router,
      refreshPage,
    };
  },
};
</script>
