// import Index from "../pages/MainIndex.vue";
// const Index = () =>
//     import ('../pages/Index.vue');

import AdminLogin from "../pages/admin/AdminLogin.vue";
import AdminDesktop from "../pages/admin/AdminDesktop.vue";
import AllUsers from "../pages/admin/AllUsers.vue";
import PeriodHistory from "../pages/admin/PeriodHistory.vue";
import SetResultGame from "../pages/admin/SetResultGame.vue";
import UserWalletHistory from "../pages/admin/UserWalletHistory.vue";
import AdminPendingRequest from "../pages/admin/AdminPendingRequest.vue";
import AdminRejectRequest from "../pages/admin/AdminRejectRequest.vue";
import AdminAcceptRecharge from "../pages/admin/AdminAcceptRecharge.vue";
import AdminRequestWithdrawal from "../pages/admin/AdminRequestWithdrawal.vue";
import AdminAcceptWithdrawal from "../pages/admin/AdminAcceptWithdrawal.vue";
import AdminRejectWithdrawal from "../pages/admin/AdminRejectWithdrawal.vue";
import AdminSetting from "../pages/admin/AdminSetting.vue";
import AdminNotification from "../pages/admin/AdminNotification.vue";
import AdminSetLavelUser from "../pages/admin/AdminSetLavelUser.vue";
import AdminLevelUser from "../pages/admin/AdminLevelUser.vue";
import AdminManageBanner from "../pages/admin/AdminManageBanner.vue";
import AdminAddBanner from "../pages/admin/AdminAddBanner.vue";
import AdminManageProduct from "../pages/admin/AdminManageProduct.vue";
import AdminAddProduct from "../pages/admin/AdminAddProduct.vue";
import period_bateing_history from "../pages/admin/period_bateing_history.vue";
import AdminQueries from "../pages/admin/AdminQueries.vue";
import AdminReferidAllUsers from "../pages/admin/AdminReferidAllUsers.vue";
import AdminUserGameHistory from "../pages/admin/AdminUserGameHistory.vue";
export const routes = [
  // {
  //   path: "/",
  //   name: "index",
  //   component: Index,
  // },
  {
    path: "/admin",
    name: "AdminLogin",
    component: AdminLogin,
  },
  {
    path: "/admin/desktop",
    name: "AdminDesktop",
    component: AdminDesktop,
  },
  {
    path: "/admin/alluser",
    name: "AllUsers",
    component: AllUsers,
  },
  {
    path: "/admin/periodhistory",
    name: "PeriodHistory",
    component: PeriodHistory,
  },
  {
    path: "/admin/setresultgame",
    name: "SetResultGame",
    component: SetResultGame,
  },
  {
    path: "/admin/adminrequestpenddingwithdrawal",
    name: "AdminRequestWithdrawal",
    component: AdminRequestWithdrawal,
  },
  {
    path: "/admin/adminacceptwithdrawal",
    name: "AdminAcceptWithdrawal",
    component: AdminAcceptWithdrawal,
  },
  {
    path: "/admin/adminrejectwithdrawal",
    name: "AdminRejectWithdrawal",
    component: AdminRejectWithdrawal,
  },
  {
    path: "/admin/userwallethistory",
    name: "UserWalletHistory",
    component: UserWalletHistory,
  },
  {
    path: "/admin/pendingrequest",
    name: "PendingRequest",
    component: AdminPendingRequest,
  },
  {
    path: "/admin/rejectrequest",
    name: "RejectRequest",
    component: AdminRejectRequest,
  },
  {
    path: "/admin/acceptrequest",
    name: "AcceptRequest",
    component: AdminAcceptRecharge,
  },
  {
    path: "/admin/setting",
    name: "setting",
    component: AdminSetting,
  },
  {
    path: "/admin/adminnotification",
    name: "AdminNotification",
    component: AdminNotification,
  },
  {
    path: "/admin/adminsetlaveluser",
    name: "AdminSetLavelUser",
    component: AdminSetLavelUser,
  },
  {
    path: "/admin/adminleveluser",
    name: "AdminLevelUser",
    component: AdminLevelUser,
  },
  {
    path: "/admin/adminmanagebanner",
    name: "AdminManageBanner",
    component: AdminManageBanner,
  },
  {
    path: "/admin/adminaddbanner",
    name: "AdminAddBanner",
    component: AdminAddBanner,
  },

  {
    path: "/admin/adminmanageproduct",
    name: "AdminManageProduct",
    component: AdminManageProduct,
  },
  {
    path: "/admin/adminaddproduct",
    name: "AdminAddProduct",
    component: AdminAddProduct,
  },
  {
    path: "/admin/period_bateing_history/:periodid",
    name: "period_bateing_history",
    component: period_bateing_history,
  },
  {
    path: "/admin/adminqueries",
    name: "AdminQueries",
    component: AdminQueries,
  },
  {
    path: "/admin/adminreferidallusers/:owncode",
    name: "AdminReferidAllUsers",
    component: AdminReferidAllUsers,
  },
  {
    path: "/admin/adminusergamehistory/:userId",
    name: "AdminUserGameHistory",
    component: AdminUserGameHistory,
  },
];
