<template>
  <div>
    <Navbar />

    <!-- sidenav -->
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-2 col-md-3 col-sm-4 col-12 pl-0">
          <SaidMenuBar />
        </div>
        <div
          class="col-lg-10 col-md-9 col-sm-8 col-12 pt-1 bg-white bg-gradient"
        >
          <h3 class="mr-0 add-bg-color-sub-header admin-add-product-title">
            Add Product
          </h3>
          <div class="row p-1">
            <div class="col-lg-12 col-md-12 col-sm-12 admin-add-product-title">
              <label for="Description"
                >Description<span class="text-danger h2">*</span></label
              >
              <textarea
                type="text"
                class="form-control border p-2 bg-white"
                id="Description"
                v-model="description"
                required
              ></textarea>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 admin-add-product-title">
              <label for="Price"
                >Price<span class="text-danger h2">*</span></label
              >
              <input
                type="text"
                class="form-control border"
                id="Price"
                v-model="price"
                required
              />
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 admin-add-product-title">
              <label for="Image"
                >Images<span class="text-danger h2"
                  >*Size(1500x1500)</span
                ></label
              >
              <input
                type="file"
                class="form-control border"
                id="Image"
                @change="uploadFile"
                required
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 d-block mx-auto p-1">
              <button
                type="button"
                class="btn btn-outline-dark float-end admin-add-product-buttons"
                @click="cancel"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-outline-dark float-end admin-add-product-buttons"
                @click="addProduct"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- loader -->
  <div class="loadings" v-show="loader" style="/* display: none; */">
    <div
      class="v-dialog v-dialog--persistent"
      style="width: 300px; display: block"
    >
      <div class="v-card v-sheet theme--dark teals-loadings">
        <div class="v-card__text">
          <span>Loading</span>
          <div
            role="progressbar"
            aria-valuemin="0"
            aria-valuemax="100"
            class="v-progress-linear mb-0"
            style="height: 7px"
          >
            <div
              class="v-progress-linear__background white"
              style="height: 7px; opacity: 0.3; width: 100%"
            ></div>
            <div class="v-progress-linear__bar">
              <div
                class="v-progress-linear__bar__indeterminate v-progress-linear__bar__indeterminate--active"
              >
                <div
                  class="v-progress-linear__bar__indeterminate long white"
                ></div>
                <div
                  class="v-progress-linear__bar__indeterminate short white"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Navbar from "./Navbar.vue";
import SaidMenuBar from "./SideMenuBar.vue";

export default {
  name: "AdminAddProduct",
  components: {
    Navbar,
    SaidMenuBar,
  },
  data() {
    return {
      description: null,
      price: null,
      image: null,
      formData: new FormData(),
      loader: false,
    };
  },
  methods: {
    uploadFile(event) {
      const file = event.target.files[0];
      this.formData.append("image", file);
    },
    cancel() {
      this.$router.push({ name: "AdminManageProduct" });
    },
    async addProduct() {
      this.loader = true;
      this.formData.append("name", this.description);
      this.formData.append("price", this.price);
      try {
        await axios.post(`/admin/addproduct`, this.formData);
        this.description = null;
        this.price = null;
        this.image = null;
        this.formData = new FormData();
        this.$router.push({ name: "AdminManageProduct" });
        this.loader = false;
      } catch (error) {
        console.error("Error occurred while submitting the form:", error);
      }
    },
  },
};
</script>
