
<script>
// import {  watch } from "vue";
// import { useRouter } from "vue-router";
// import { useWindowFocus } from '@vueuse/core'

export default {
    setup() {
        // const focused = useWindowFocus()
        // const router = useRouter();
        // watch(focused, (isFocused) => {
        //     if (isFocused) {
        //         router.go(0)
        //     }
        // })


        return {

        }
    },
}
</script>
