<template>
  <!-- <link rel="stylesheet" :href="'/css/add-redenvelope.css'" /> -->

  <div class="appHeader1">
    <div class="left">
      <a href="#" v-on:click="router.go(-1)" class="icon goBack">
        <img :src="require('../assets/images/left.png')" alt="" />
      </a>
      <div class="pageTitle">Add Red Envelope</div>
    </div>
  </div>

  <section class="content content-add-red-envelope">
    <Form
      @submit="onSubmit"
      method="post"
      id="Addenvelope"
      class="card-body"
      autocomplete="off"
    >
      <div class="form-group add-border-bottom">
        <label>Fixed Mony</label>
        <Field
          type="number"
          v-model="Amount"
          :value="Amount"
          :rules="Aalidateamount"
          name="Amount"
          id="Amount"
          onKeyPress=""
          class="form-control"
        />
        <ErrorMessage name="Amount" />
      </div>
      <div class="form-group add-border-bottom">
        <label>Enter Your Login Password</label>
        <Field
          type="text"
          v-model="ForgotPasswordVue"
          :value="ForgotPasswordVue"
          :rules="validateForgotPasswordVue"
          name="ForgotPasswordVue"
          id="ForgotPasswordVue"
          onKeyPress=""
          class="form-control"
        />
        <ErrorMessage name="ForgotPasswordVue" />
      </div>
      <div class="lunch-button">
        <button type="submit" class="btn-lunch" onclick="()">Launch</button>
      </div>
    </Form>
    <div id="toast">
      <div class="checkicon"></div>
      You can't send a red envelope
    </div>
  </section>
  <div class="toast-content" v-show="isErrorShow">
    <div class="toast-message">{{ errorMessage }}</div>
  </div>
  <Footer />
</template>

<script>
import axios from "axios";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { Form, Field, ErrorMessage } from "vee-validate";
import Footer from "../components/CommonFooter.vue";

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
    Footer,
  },
  setup() {
    // useMeta({ title: "Add Red Envelope Page" });
    const router = useRouter();
    const isErrorShow = ref(false);
    const errorMessage = ref("");

    if (!localStorage.getItem("authToken")) {
      router.push({ path: "/login" });
    }
    const onSubmit = async (values) => {
      axios
        .post("/add_redenvelope", {
          amount: values.Amount,
          password: values.ForgotPasswordVue,
        })
        .then((response) => {
          if (response.data.success) {
            setErrorMessage(response.data.message);
          } else {
            setErrorMessage(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error.data.error);
        });
    };
    const setErrorMessage = (value) => {
      errorMessage.value = value;
      isErrorShow.value = true;
      setInterval(() => {
        errorMessage.value = "";
        isErrorShow.value = false;
      }, 5000);
    };
    return {
      router,
      onSubmit,
      setErrorMessage,
      isErrorShow,
      errorMessage,
    };
  },
};
</script>

<!-- <style scoped>

@import "../assets/css/add-redenvelope.css";

</style> -->
