<template>
  <MDBNavbar expand="lg" light class="header-sticky sidebar-bg-color-set">
    <MDBNavbarBrand href="#" class="text-white">
      <i class="bi bi-person-fill"></i>
      <span class="pl-1">Admin</span>
    </MDBNavbarBrand>
    <!-- <nav
      class="navbar navbar-dark bg-dark p-0 d-flex justify-content-center align-items-center"
    >
      <span class="navbar-brand mb-0">
        <img
          src="../../assets/images/admin.jpg"
          alt="ok"
          class="img-fluid mb-0 p-0 rounded"
        />
      </span>
    </nav> -->
  </MDBNavbar>
</template>

<script>
import { MDBNavbar, MDBNavbarBrand } from "mdb-vue-ui-kit";
import { ref } from "vue";
export default {
  name: "NavBar",
  components: {
    MDBNavbar,
    MDBNavbarBrand,
  },
  setup() {
    const collapse1 = ref(false);
    const dropdown1 = ref(false);
    return {
      collapse1,
      dropdown1,
    };
  },
};
</script>
<style>
.header-sticky {
  position: sticky;
  z-index: 2;
  top: 0;
}
</style>
