<template>
  <Navbar />

  <!-- sidenav -->
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-2 col-md-3 col-sm-4 col-12 pl-0">
        <SaidManuBar />
      </div>
      <div class="col-lg-10 col-md-9 col-sm-8 col-12 bg-white bg-gradient">
        <h3 class="p-1 mr-0 m-1 add-bg-color-sub-header">Dashboard</h3>
        <div v-if="loader === false">
          <div class="container p-3 bg-secondary-subtle">
            <h5 class="p-0 mr-0 dashboard-tiles-title">
              Statistics of user data information.
            </h5>
            <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3">
              <div class="col">
                <div class="bg-info-subtle p-3 dashboard-tiles-card">
                  <h5>Total User</h5>
                  <!-- <p class="text-start">250000</p> -->
                  <p class="text-start">
                    {{ adminGetData.TotalUser }}
                  </p>
                </div>
              </div>
              <div class="col">
                <div class="bg-info-subtle p-3 dashboard-tiles-card">
                  <h5>User Balance</h5>
                  <!-- <p class="text-start">50000</p> -->
                  <p class="text-start">
                    {{ adminGetData.TotalBalance?.toFixed(2) }}
                  </p>
                </div>
              </div>
              <div class="col">
                <div class="bg-info-subtle p-3 dashboard-tiles-card">
                  <h5>Active User</h5>
                  <!-- <p class="text-start">1</p> -->
                  <p class="text-start">{{ adminGetData.ActiveUser }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="container p-3 bg-secondary-subtle">
            <h5 class="p-0 mr-0 dashboard-tiles-title">
              Statistics of today recharge and withdrawal
            </h5>
            <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3">
              <div class="col">
                <div class="bg-info-subtle p-3 dashboard-tiles-card">
                  <h5>Today Recharge</h5>
                  <!-- <p class="text-start">0</p> -->
                  <p class="text-start">
                    {{ adminGetData.TodayRecharge?.toFixed(2) }}
                  </p>
                </div>
              </div>
              <div class="col">
                <div class="bg-info-subtle p-3 dashboard-tiles-card">
                  <h5>Today Withdrawal</h5>
                  <!-- <p class="text-start">0</p> -->
                  <p class="text-start">
                    {{ adminGetData.TodayWithdrawal?.toFixed(2) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="container p-3 bg-secondary-subtle">
            <h5 class="p-0 mr-0 dashboard-tiles-title">
              Statistics of today profit and loss
            </h5>
            <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3">
              <div class="col">
                <div class="bg-info-subtle p-3 dashboard-tiles-card">
                  <h5>Loss</h5>
                  <!-- <p class="text-start">-546456</p> -->
                  <p class="text-start">{{ adminGetData.Loss?.toFixed(2) }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="container p-3 bg-secondary-subtle">
            <h5 class="p-0 mr-0 dashboard-tiles-title">
              Statistics of recharge and withdrawal summary
            </h5>
            <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3">
              <div class="col mt-3">
                <div class="bg-info-subtle p-3 dashboard-tiles-card">
                  <h5>Pending Recharges</h5>
                  <!-- <p class="text-start">0</p> -->
                  <p class="text-start">
                    {{ adminGetData.PendingRecharges?.toFixed(2) }}
                  </p>
                </div>
              </div>
              <div class="col mt-3">
                <div class="bg-info-subtle p-3 dashboard-tiles-card">
                  <h5>Success Recharge</h5>
                  <!-- <p class="text-start">123242</p> -->
                  <p class="text-start">
                    {{ adminGetData.SuccessRecharge?.toFixed(2) }}
                  </p>
                </div>
              </div>
              <div class="col mt-3">
                <div class="bg-info-subtle p-3 dashboard-tiles-card">
                  <h5>Reject Recharge</h5>
                  <!-- <p class="text-start">15436456</p> -->
                  <p class="text-start">
                    {{ adminGetData.RejectRecharge?.toFixed(2) }}
                  </p>
                </div>
              </div>
            </div>
            <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3">
              <div class="col mt-3">
                <div class="bg-info-subtle p-3 dashboard-tiles-card">
                  <h5>Pending Withdrawal Requests</h5>
                  <!-- <p class="text-start">1657576</p> -->
                  <p class="text-start">
                    {{ adminGetData.PendingWithdrawalRequests?.toFixed(2) }}
                  </p>
                </div>
              </div>
              <div class="col mt-3">
                <div class="bg-info-subtle p-3 dashboard-tiles-card">
                  <h5>Approved Withdrawal Requests</h5>
                  <!-- <p class="text-start">1</p> -->
                  <p class="text-start">
                    {{ adminGetData.ApprovedWithdrawalRequests?.toFixed(2) }}
                  </p>
                </div>
              </div>
              <div class="col mt-3">
                <div class="bg-info-subtle p-3 dashboard-tiles-card">
                  <h5>Reject Withdrawal Requests</h5>
                  <!-- <p class="text-start">1</p> -->
                  <p class="text-start">
                    {{ adminGetData.RejectWithdrawalRequests?.toFixed(2) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="container p-3 bg-secondary-subtle">
            <h5 class="p-0 mr-0 dashboard-tiles-title">
              Statistics of profit and loss
            </h5>
            <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3">
              <div class="col">
                <div class="bg-info-subtle p-3 dashboard-tiles-card">
                  <h5>Profit</h5>
                  <!-- <p class="text-start">546456</p> -->
                  <p class="text-start">
                    {{ adminGetData.Profit?.toFixed(2) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- loader -->
  <div class="loadings" v-show="loader" style="/* display: none; */">
    <div
      class="v-dialog v-dialog--persistent"
      style="width: 300px; display: block"
    >
      <div class="v-card v-sheet theme--dark teals-loadings">
        <div class="v-card__text">
          <span>Loading</span>
          <div
            role="progressbar"
            aria-valuemin="0"
            aria-valuemax="100"
            class="v-progress-linear mb-0"
            style="height: 7px"
          >
            <div
              class="v-progress-linear__background white"
              style="height: 7px; opacity: 0.3; width: 100%"
            ></div>
            <div class="v-progress-linear__bar">
              <div
                class="v-progress-linear__bar__indeterminate v-progress-linear__bar__indeterminate--active"
              >
                <div
                  class="v-progress-linear__bar__indeterminate long white"
                ></div>
                <div
                  class="v-progress-linear__bar__indeterminate short white"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SaidManuBar from "./SideMenuBar.vue";
import Navbar from "./Navbar.vue";
import axios from "axios";
import { ref } from "vue";
export default {
  name: "AdminDesktop",
  components: {
    Navbar: Navbar,
    SaidManuBar: SaidManuBar,
  },
  mounted() {
    const user = localStorage.getItem("user-info");
    if (!user) {
      this.$router.push({ name: "AdminLogin" });
    }
  },
  setup() {
    const apiRun = ref(true);
    const loader = ref(false);
    const adminGetData = ref([]);

    const getAdminData = async () => {
      apiRun.value = true;
      loader.value = true;
      await axios
        .get("/admin/desktop", {})
        .then((response) => {
          adminGetData.value = response.data.data;

          console.log("admin get data", adminGetData.value);
          loader.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getAdminData();

    return {
      getAdminData,
      apiRun,
      adminGetData,
      loader,
    };
  },
};
</script>
